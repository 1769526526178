import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/InfoOutlined";
const ExtrasIncrementer = ({
  extras,
  setExtras,
  totalDays,
  insuranceValue,
  vehicleGroup,
}) => {
  const { t } = useTranslation();
  const [fullInsurance, setFullInsurance] = useState(0);

  const checkExcessValue = (vehicleGroup) => {
    switch (vehicleGroup) {
      case "SCOOTER":
        return `${t("excessValue")} 550€`;
      case "MDMV":
      case "NBMV":
      case "NCMV":
        return `${t("excessValue")} 1000€`;
      case "EDMV":
      case "EDMD":
      case "HXMD":
      case "MDAV":
      case "EDAV":
        return `${t("excessValue")} 1500€`;
      case "CFMV":
      case "CDMV":
      case "CGMD":
      case "CDMD":
      case "HDMD":
      case "CMMR":
      case "CWMD":
      case "NTMV":
      case "CTMV":
      case "CTAV":
      case "CDAV":
      case "CDAD":
      case "SDMD":
      case "RFMD":
      case "CGAV":
      case "CGAD":
      case "TXT":
        return `${t("excessValue")} 2000€`;
      case "LVMD":
      case "RVAD":
      case "DDAD":
      case "SVMD":
      case "SFAD":
      case "JVMR":
        return `${t("excessValue")} 3000€`;
      case "PDAD":
        return `${t("excessValue")} 3800€`;
      default:
        break;
    }
  };

  useEffect(() => {
    let updatedExtras = extras.map((extra, index) =>
      index === 4 && extra.id === 5
        ? { ...extra, value: insuranceValue }
        : extra
    );

    setExtras(updatedExtras);
  }, []);

  const handleIncrement = (index) => {
    const updatedExtras = [...extras];

    if (updatedExtras[index].id === 5) {
      updatedExtras[index].value = insuranceValue;
    }

    if (updatedExtras[index].totalByDay) {
      if (updatedExtras[index].quantity < updatedExtras[index].max) {
        updatedExtras[index].quantity += 1;
        updatedExtras[index].totalValue =
          updatedExtras[index].quantity *
            updatedExtras[index].value *
            totalDays >
          updatedExtras[index].maxValue
            ? updatedExtras[index].maxValue
            : updatedExtras[index].quantity *
              updatedExtras[index].value *
              totalDays;
        setExtras(updatedExtras);
      }
    } else {
      if (updatedExtras[index].quantity < updatedExtras[index].max) {
        updatedExtras[index].quantity += 1;
        updatedExtras[index].totalValue =
          updatedExtras[index].quantity * updatedExtras[index].value >
          updatedExtras[index].maxValue
            ? updatedExtras[index].maxValue
            : updatedExtras[index].quantity * updatedExtras[index].value;
        setExtras(updatedExtras);
      }
    }
  };

  const handleChangeInsurance = (value) => {
    const updatedExtras = [...extras];
    setFullInsurance(value);

    updatedExtras[4].value = insuranceValue;
    updatedExtras[4].quantity = value;
    updatedExtras[4].totalValue =
      updatedExtras[4].quantity * updatedExtras[4].value * totalDays >
      updatedExtras[4].maxValue
        ? updatedExtras[4].maxValue
        : updatedExtras[4].quantity * updatedExtras[4].value * totalDays;

    setExtras(updatedExtras);
  };

  const handleDecrement = (index) => {
    const updatedExtras = [...extras];
    if (updatedExtras[index].quantity > updatedExtras[index].min) {
      updatedExtras[index].quantity -= 1;
      updatedExtras[index].totalValue =
        updatedExtras[index].quantity * updatedExtras[index].value;
      setExtras(updatedExtras);
    }
  };

  const extraPerDayChecker = (id) => {
    switch (id) {
      case 0:
        return t("perDay");
      case 1:
        return t("perDay");
      case 2:
        return t("perDay");
      case 5:
        return t("perDay");
      case 7:
        return "";
      default:
        return t("perRent");
    }
  };

  return (
    <div>
      {extras
        ?.filter((extra) => extra.id !== 3 && extra.id !== 7) // Exclude unwanted IDs
        .sort((a, b) => (a.id === 5 ? 1 : b.id === 5 ? -1 : 0)) // Move id === 5 to the end
        .map((extra, index) => (
          <Container sx={{ marginBottom: "10px" }} key={index}>
            {extra.id === 5 ? (
              <FormLabel sx={{ marginRight: "10px", color: "black" }}>
                <Typography variant="h5">{t("chooseInsurance")}</Typography>
              </FormLabel>
            ) : (
              <FormLabel sx={{ marginRight: "10px", color: "black" }}>
                <Typography>
                  <b>{t(extra.extraName)}</b>{" "}
                  {extra.value === 0 ? insuranceValue : extra.value}€{" "}
                  {extraPerDayChecker(extra.id)}{" "}
                </Typography>
              </FormLabel>
            )}

            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              {extra.id === 5 ? (
                <>
                  <RadioGroup
                    aria-labelledby="fullInsurance"
                    name="Full Insurance"
                    value={fullInsurance}
                    onChange={(e) => handleChangeInsurance(e.target.value)}
                    sx={{ margin: 1 }}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label={
                        <Typography>
                          <b>{t(extra.extraName)}</b>{" "}
                          <Tooltip title={t("fullInsuranceInfo")}>
                            <InfoIcon />
                          </Tooltip>{" "}
                          {extra.value === 0 ? insuranceValue : extra.value}€{" "}
                          {extraPerDayChecker(extra.id)}{" "}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label={
                        <Typography>
                          <b>{checkExcessValue(vehicleGroup)}</b>
                          <Tooltip title={t("excessInfo")}>
                            <InfoIcon />
                          </Tooltip>{" "}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </>
              ) : (
                <>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleDecrement(index)}
                    disabled={extra.quantity <= extra.min}
                  >
                    <Typography variant="button" display="block" gutterBottom>
                      -
                    </Typography>
                  </Button>
                  <span
                    style={{
                      width: "20px",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {extra.quantity}
                  </span>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleIncrement(index)}
                    disabled={extra.quantity >= extra.max}
                  >
                    <Typography variant="button" display="block" gutterBottom>
                      +
                    </Typography>
                  </Button>
                </>
              )}
            </ButtonGroup>
          </Container>
        ))}
    </div>
  );
};

ExtrasIncrementer.propTypes = {
  extras: PropTypes.any.isRequired,
  setExtras: PropTypes.func.isRequired,
  totalDays: PropTypes.number.isRequired,
  insuranceValue: PropTypes.number.isRequired,
  vehicleGroup: PropTypes.string.isRequired,
};

export default ExtrasIncrementer;
