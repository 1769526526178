import React from "react";
import { Modal, Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

// ErrorModal Component to display the errors
const ErrorModal = ({ open, handleClose, errors }) => {
  const { t } = useTranslation();

  const checkErrorType = (field) => {
    switch (field) {
      case "date_pickup":
        return t("deliveryDate");
      case "date_return":
        return t("returnDate");
      case "time_pickup":
        return t("deliveryTime");
      case "time_return":
        return t("returnTime");
      default:
        break;
    }
  };

  return (
    <>
      {Object?.keys(errors).length > 0 ? (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            {/* Display the errors */}
            {Object.keys(errors).length > 0 ? (
              <ul>
                {Object.entries(errors).map(([field, error], index) => (
                  <li key={index}>
                    <Typography variant="h6" color="error">
                      {checkErrorType(field)}:
                    </Typography>{" "}
                    <Typography>{error.message}</Typography>
                  </li>
                ))}
              </ul>
            ) : (
              <Typography>No errors</Typography>
            )}
            {/* Close Button */}
            <Button onClick={handleClose} sx={{ mt: 2 }}>
              {t("close")}
            </Button>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default ErrorModal;
