import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "pt",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        home: "Home",
        contacts: "Contacts",
        contactUs: "Contact-us",
        about: "About",
        fleet: "Fleet",
        address: "Address",
        phone: "Phone",
        informations: "Informations",
        information: "Information",
        termsAndConditions: "Terms & Conditions",
        privacyPolicy: "Privacy Policy",
        fullName: "Full Name",
        fullNameIsRequired: "Full name is required",
        fullNameInvalid: "Full name must have at least 3 characters",
        email: "Email",
        emailIsRequired: "Email is required",
        emailInvalid: "Email is not valid",
        phoneNumber: "Phone number",
        phoneNumberIsRequired: "Phone number is required",
        phoneNumberInvalid: "Phone number must have at least 9 characters",
        message: "Message",
        messageIsRequired: "Message is required",
        messageInvalid: "Message must have at least 3 characterss",
        submit: "Send",
        monday: "Monday",
        sunday: "Sunday",
        legal: "Legal",
        Portuguese: "Portuguese",
        Spanish: "Spanish",
        English: "English",
        German: "German",
        French: "French",
        emailSent: "Email sent successfully.",
        wipInfo:
          "Updates underway! Our reservation process is currently being improved for a better booking experience. Thank you for your patience.",
        deliveryDesk: "Pick-up Place",
        returnDesk: "Return Place",
        deliveryDate: "Pick-up Date",
        returnDate: "Return Date",
        deliveryTime: "Pick-up Time",
        returnTime: "Return Time",
        hotel: "Hotel",
        flight: "Flight",
        rentalPeriod: "Rental Period",
        myVehicle: "My Vehicle",
        optionals: "Optionals",
        clientData: "Client data",
        payment: "Payment",
        confirmation: "Confirmation",
        pickUp: "Pick-up",
        dropOff: "Return",
        group: "Group",
        orSimilar: "Or Similiar",
        carDetails: "Car Details",
        fuelType: "Fuel Type",
        gearbox: "Gearbox",
        seats: "Seats",
        doors: "Doors",
        from: "From",
        day: "Day",
        reserve: "Reserve",
        noCarsAvaiable: "No cars avaiable for the requested dates.",
        myCar: "My Car",
        myReservation: "My Reservation",
        carPerDayValue: "Daily rate",
        per: "per",
        days: "days",
        extrasChoosed: "Extras choosed",
        extra: "Extra",
        cost: "Cost",
        totalPrice: "Total Price",
        partialPrice: "Partial value",
        continue: "Continue",
        clientDetails: "Client Details",
        birthDate: "Birth Date",
        city: "City",
        postalCode: "Postal Code",
        country: "Country",
        temporaryAddress: "Temporary Address",
        totalRentalPriceIs: "The total rental price is",
        bookingConfirmed: "Your Booking is Confirmed!",
        bookingSummary:
          "Thank you for your order! Here's a summary of your purchase",
        orderId: "Order ID",
        vehicleChoosed: "Vehicle Choosed",
        model: "Model",
        furtherQuestions: "For further questions, you can contact our team.",
        bestRegards: "Best Regards",
        search: "Search",
        about1:
          "M.I.Nunes, Unip., Lda., now under the commercial brand Why Not Car Rental, has more than forty years of experience in the market, and is represented in the center of Funchal, as well as 3 minutes from the airport. We have a diverse and very recent fleet of vehicles and scooters. If safety and comfort combined with quality and an affordable price are priorities for you, do not hesitate to contact a rental company recognized for its level of demand in terms of services and fleet.",
        about2:
          "At Why Not Car Rental, we believe that the freedom to explore shouldn't compromise your wallet. That's why we're committed to offering the best prices on the market. We work hard to ensure that our guests enjoy competitive rates, allowing them to make the most of their stay on the island of Madeira.",
        about3:
          "To make your experience even more enjoyable, Why Not Car Rental offers airport transportation. Nothing is more reassuring than knowing that, after your trip, one of our professionals will be ready to take you back to the airport, ensuring a smooth transition between your arrival and departure.",
        ourHistory: "Our Story",
        promiseFulfilled: "A Promise Fulfilled",
        moreConvenienceLessStress: "More Convenience, Less Stress",
        chooseYourWay: "Choose your way",
        hotelDeliveryInfo:
          "In this location is only possible to deliver and return cars in Hotels! (A fee of deliver and return will be applied 10 euros each way!)",
        airportDeliveryInfo: "Please Intruduce your flight Number",
        automatic: "Automatic",
        manual: "Manual",
        countryCode: "Country Code",
        outsideBusinessHours: "Outside of business hours (08:30 - 18:30)",
        pickUpDateValidation:
          "We're sorry, but vehicle rentals are only possible with a minimum notice period of 3 days from the current date. Please select a new date that fits within this range.",
        returnDateValidation: "Return date must be after Pick-up date",
        validDate: "The date is not valid",
        mandatory: "The field is mandatory",
        diesel: "Diesel",
        petrol: "Petrol",
        vehicleType: "Vehicle Type",
        small: "Small",
        medium: " Medium",
        large: " Grande",
        suv: " SUV",
        cabrio: " Cabriolet",
        miniVan: " Mini Van",
        van: " Van",
        eletric: "Eletric",
        premium: "Premium",
        babySeat: "Baby Seat",
        babySeatIsofix: "Baby Seat Isofix",
        boosterSeat: "Booster Seat",
        fullInsurance: "Full Insurance",
        youngDriver: "Young Driver",
        additionalDriver: "Additional Driver",
        outOfHoursReturn: "Out of hours drop off",
        outOfHoursDeliverAfter23: "Out of hours deliver after 23h00",
        onlyString: "Only alphabets are allowed for this field",
        moreThan18YearsOld: "You need to be 21 years old or older",
        validCountryCode: "Insert a valid country code",
        iAmYoungDriver: "I am a young driver 21 to 25 years old",
        location: "Location",
        date: "Date",
        time: "Time",
        close: "Close",
        perDay: "Per day",
        perRent: "Per rental",
        deskInfoMessage:
          "All the deliveries and drop offs in this location need to be done in an hotel!",
        title: "explore Madeira island? Book your car now!",
        requestQuote: "Request quote",
        quoteMessage: "I want a quote for the following group ",
        requestCarUnavaiable:
          "The requested car is not avaiable at the moment. Please choose another car, or contact us by email to check the avaiability of the requested car.",
        seeAll: "See All",
        youngDriverInfo:
          "A young drivers fee will be aplied in a value of 40 euros (21 - 25 years)",
        groupPreference: "Car Preference",
        reviews: "Recent Reviews",
        leaveAReview: "Write a review!",
        reservationDetails: "Rervation Details",
        changeDates: "Change Dates",
        aboutUsText:
          "Looking to rent a car in Madeira Island? Why Not Car Rental offers affordable, reliable car hire in Madeira airport, Funchal and across the island. With a diverse fleet and flexible rental options, we make exploring Madeira easy and convenient. Enjoy transparent pricing, easy pickup, and a stress-free booking experience. Discover Madeira at your own pace ! Book your car rental with us today!",
        reviewOf: "Review of ",
        timePickUpAfterReturn:
          "Pick-up time can´t be superior than the return time!",
        datesCanTBeEqual: "Dates can´t be equal!",
        datePlaceHolder: "Enter date in DD/MM/YYYY format",
        yes: "Yes",
        no: "No",
        fullInsuranceInfo:
          "This insurance covers all types of damages except: damage to the vehicle's interior, use of incorrect fuel, damage to the underside of the vehicle, and situations resulting from driver negligence. For any questions, please refer to the terms and conditions.",
        chooseInsurance: "Select an Insurance option",
        excessValue: "Excess in the value of ",
        excessInfo:
          "This deposit must be made by CREDIT CARD when the car is delivered. The stated deposit will be refunded to the client at the end of the rental period, provided no accident has occurred and no damage has been caused to the vehicle due to acts of vandalism, theft of the vehicle, or any of its accessories. This requirement can only be waived with the payment of Super C.D.W. (Comprehensive Insurance).",
        scooter: "Scooter",
        mandatoryExcess: "Non Waiverable responsibility in a value of 1000€",
        bigCar: "Large Car",
        filter: "Vehicles Filter",
        noFilteredCarsAvaiable:
          "Sorry, there are no cars available with the requested features.",
        withCanvasEletricRoof: "with Canvas Eletric Roof",
        withPanoramicRoof: "with Panoramic Roof",
        readAndAccepted: "I have read and Accepted",
        choosePaymentMethod: "Choose the payment method",
        choosePaymentMode: "Choose the payment mode",
        pay20Now80Later: "Pay 20% now and 80% on the pick-up",
        proceedPayment: "Proceed to the payment",
        at: "at",
        partialValuePaid:
          "You only paid 20% of the rental, at the pick-up you need to pay the rest of the value!",
        payed: "You payed the value of ",
        totalValuePaid: "You paid the total value of the rental!",
        hello: "Hello",
      },
    },
    pt: {
      translation: {
        home: "Home",
        contacts: "Contactos",
        contactUs: "Contacte-nos",
        about: "Sobre nós",
        fleet: "Frota",
        address: "Morada",
        phone: "Telefone",
        informations: "Informações",
        termsAndConditions: "Termos e Condições",
        privacyPolicy: "Politica de Privacidade",
        fullName: "Nome Completo",
        fullNameIsRequired: "Nome é obrigatório",
        fullNameInvalid: "O nome deve ter pelo menos 3 caracteres",
        email: "Email",
        emailIsRequired: "Email é obrigatório",
        emailInvalid: "O Email não é valido",
        phoneNumber: "Número de telefone",
        phoneNumberIsRequired: "Número de telefone é obrigatório",
        phoneNumberInvalid:
          "O número de telefone deve ter pelo menos 9 caracteres",
        message: "Mensagem",
        messageIsRequired: "Mensagem é obrigatório",
        messageInvalid: "A mensagem deve ter pelo menos 3 caracteres",
        submit: "Enviar",
        monday: "Segunda-Feira",
        sunday: "Domingo",
        legal: "Legal",
        Portuguese: "Portugues",
        Spanish: "Espanhol",
        English: "Inglês",
        German: "Alemão",
        French: "Francês",
        emailSent: "Email enviado com sucesso.",
        wipInfo:
          "Atualizações em andamento! Nosso processo de reservas está a ser melhorado para uma melhor experiência de reserva. Obrigado pela sua paciência.",
        deliveryDesk: "Local de Levantamento",
        returnDesk: "Local de Devolução",
        deliveryDate: "Data de Levantamento",
        returnDate: "Data de Devolução",
        deliveryTime: "Hora de Levantamento",
        returnTime: "Hora de Devolução",
        hotel: "Hotel",
        flight: "Nº de Voo",
        rentalPeriod: "Período de Aluguer",
        myVehicle: "A minha viatura",
        optionals: "Opcionais",
        clientData: "Dados Cliente",
        payment: "Pagamento",
        confirmation: "Confirmação",
        pickUp: "Levantamento",
        dropOff: "Devolução",
        group: "Grupo",
        orSimilar: "Ou Similar",
        carDetails: "Detalhes da viatura",
        fuelType: "Combustível",
        gearbox: "Transmissão",
        seats: "Lugares",
        doors: "Portas",
        from: "Desde",
        day: "Dia",
        reserve: "Reservar",
        noCarsAvaiable: "Não há carros disponíveis para as datas selecionadas.",
        myCar: "A minha Viatura",
        myReservation: "A minha Reserva",
        carPerDayValue: "Tarifa diária",
        per: "por",
        days: "dias",
        extrasChoosed: "Extras escolhidos",
        extra: "Extra",
        cost: "Custo",
        totalPrice: "Preço Total",
        partialPrice: "Valor Parcial",
        continue: "Continuar",
        clientDetails: "Dados Cliente",
        birthDate: "Data de Nascimento",
        city: "Cidade",
        postalCode: "Codigo Postal",
        country: "País",
        temporaryAddress: "Endereço Temporário",
        totalRentalPriceIs: "O preço total do aluguer é",
        bookingConfirmed: "A sua reserva foi confirmada!",
        bookingSummary:
          "Obrigado pela sua reserva! Aqui está um resumo da sua reserva!",
        orderId: "ID da reserva",
        vehicleChoosed: "Viatura Escolhida",
        model: "Modelo",
        furtherQuestions:
          "Em caso de alguma dúvida, não hesite em contactar a nossa equipa.",
        bestRegards: "Com os melhores cumprimentos",
        search: "Pesquisar",
        about1:
          "A M.I.Nunes, Unip., Lda. agora com a marca comercial Why Not Car Rental, conta com mais de quarenta anos de experiência no mercado, e, está representada no centro do Funchal, bem como a 3 minutos do aeroporto. Dispomos de uma frota de viaturas e scooters diversificada e muito recente. Se a segurança e o conforto aliados à qualidade e a um preço acessível são prioridades para si, não hesite em contactar uma companhia de aluguer reconhecida pelo seu grau de exigência quer a nível dos serviços, quer da frota.",
        about2:
          "Na Why Not Car Rental, acreditamos que a liberdade de explorar não deve comprometer o seu bolso. É por isso que nos comprometemos a oferecer os melhores preços do mercado. Trabalhamos arduamente para garantir que os nossos clientes desfrutem de tarifas competitivas, permitindo-lhes tirar o máximo proveito da sua estadia na ilha da Madeira.",
        about3:
          "Para tornar a sua experiência ainda mais agradável, a Why Not Car Rental oferece transporte para o aeroporto. Nada é mais reconfortante do que saber que, após a sua viagem, um dos nossos profissionais estará pronto para o levar de volta ao aeroporto, garantindo uma transição suave entre a sua chegada e partida.",
        ourHistory: "A nossa história",
        promiseFulfilled: "Uma promessa cumprida",
        moreConvenienceLessStress: "Mais conveniência, menos stress",
        chooseYourWay: "Escolha a sua medida",
        hotelDeliveryInfo:
          "Nesta localização é apenas possível levantar e devolver as viaturas em hoteis! Uma taxa de levantamento e de devolução será aplicada. (10 euros cada)",
        airportDeliveryInfo: "Por favor introduza o numero de Voo",
        automatic: "Automático",
        manual: "Manual",
        countryCode: "Indicativo",
        outsideBusinessHours: "Fora de horas de serviço (08:30 - 18:30)",
        pickUpDateValidation:
          "Lamentamos, mas o aluguer de veículos só é possível com uma antecedência mínima de 3 dias a partir da data atual. Por favor, selecione uma nova data que respeite este intervalo.",
        returnDateValidation:
          "Data de devolução tem que ser superior a data de levantamento!",
        validDate: "A data não é válida",
        mandatory: "O campo é obrigatório",
        diesel: "Gasóleo",
        petrol: "Gasolina",
        vehicleType: "Tipo de viatura",
        small: "Pequeno",
        medium: "Médio",
        large: "Grande",
        suv: "SUV",
        cabrio: "Cabriolet",
        miniVan: "Monovolume",
        van: "Carrinha",
        eletricCar: "Elétrico",
        premium: "Premium",
        babySeat: "Cadeira de bebê",
        babySeatIsofix: "Cadeira de bebê com isofix",
        boosterSeat: "Banco elevatório",
        fullInsurance: "Seguro Completo",
        youngDriver: "Condutor Jovem",
        additionalDriver: "Condutor Adicional",
        outOfHoursReturn: "Devolução fora de horas",
        outOfHoursDeliverAfter23: "Devolução fora de horas após as 23h00",
        onlyString: "Apenas letras são permitidas",
        moreThan21YearsOld: "Têm que ter pelo menos 21 anos de idade",
        validCountryCode: "Introduza um código válido",
        iAmYoungDriver: "Sou um jovem condutor de 21 a 25 anos",
        location: "Localização",
        date: "Data",
        time: "Hora",
        close: "Fechar",
        perDay: "Por dia",
        perRent: "Por aluguer",
        deskInfoMessage:
          "Todas os levantamentos e devoluções nesta localização têm que ser realizadas num hotel!",
        title: "explorar a ilha da Madeira? Reserve o seu carro agora!",
        moreThan18YearsOld: "A idade tem que ser igual ou superior a 21 anos!",
        requestQuote: "Pedir cotação",
        quoteMessage: "Queria uma cotação para o seguinte grupo ",
        information: "Informação",
        requestCarUnavaiable:
          "A viatura escolhida de momento não se encontra disponível. Por favor escolha outra viatura, ou então pode nos contactar por email para verificar a disponibilidade da mesma.",
        seeAll: "Ver todos",
        youngDriverInfo:
          "Uma taxa de condutor jovem será aplicada no valor de 40 euros (21 - 25 anos)",
        groupPreference: "Grupo escolhido",
        reviews: "Avaliações Recentes",
        leaveAReview: "Faça uma avaliação!",
        reservationDetails: "Detalhes da reserva",
        changeDates: "Alterar datas",
        aboutUsText:
          "Quer alugar um carro na Ilha da Madeira? A Why Not Car Rental oferece preços acessíveis, carros fiáveis e levantamentos no aeroporto da Madeira, Funchal e em toda a ilha. Com uma frota diversificada e opções de aluguer flexíveis, tornamos a exploração da Madeira fácil e conveniente. Desfrute de preços transparentes, devolução fácil e sem stress. Descubra a Madeira ao seu ritmo! Reserve hoje mesmo o seu aluguer de carro connosco!",
        reviewOf: "Crítica de ",
        timePickUpAfterReturn:
          "A hora de levantamento nao pode ser superior a hora de recolha!",
        datesCanTBeEqual: "As datas não podem ser iguais!",
        datePlaceHolder: "Introduza a data no formato DD/MM/YYYY",
        yes: "Sim",
        no: "Não",
        fullInsuranceInfo:
          "Este seguro cobre todos os tipos de danos, exceto: danos no interior da viatura, uso de combustível inadequado, danos na parte inferior do veículo e situações decorrentes de negligência do condutor. Em caso de dúvidas, consulte os termos e condições para mais informações.",
        chooseInsurance: "Selecione uma opção de Seguro",
        excessValue: "Franquia no valor de ",
        excessInfo:
          "Esta fránquia deve ser feita no levantamento do carro por CARTÃO DE CRÉDITO. A fránquia referida será reembolsada ao cliente no fim do aluguer, caso nenhum acidente tenha ocorrido , nem como nenhum dano causado ao veículo por atos de Vandalismo, roubo do veículo ou de qualquer um de seus acessórios. Esta situação só pode ser eliminada com o pagamento de Super C.D.W. (Seguro todos os riscos)",
        scooter: "Scooter",
        mandatoryExcess: "Franquia obrigatória no valor de 1000€",
        bigCar: "Carro Grande",
        filter: "Filtro de veículos",
        noFilteredCarsAvaiable:
          "Desculpe, não há carros disponíveis com as características solicitadas.",
        withCanvasEletricRoof: "com Teto de lona elétrico",
        withPanoramicRoof: "com teto Panorâmico",
        readAndAccepted: "Li e Aceito",
        choosePaymentMethod: "Selecione o método de pagamento",
        choosePaymentMode: "Selecione a modalidade de pagamento",
        pay20Now80Later: "Pague 20% agora e 80% no levantamento",
        proceedPayment: "Proceder para o pagamento",
        at: "às",
        partialValuePaid:
          "Apenas pagou 20% do aluguer. No levantamento da viatura terá que pagar os restantes 80%!",
        totalValuePaid: "Pagou a totalidade do aluguer!",
        payed: "Pagou o valor de ",
        hello: "Olá",
      },
    },
    // es: {
    //   translation: {
    //     home: "Casa",
    //     contacts: "Contactos",
    //     about: "Acerca de",
    //     fleet: "Flota",
    //     address: "Dirección",
    //     phone: "Teléfono",
    //     informations:"Informaciones",
    //     termsAndConditions: "Términos y Condiciones",
    //     privacyPolicy: "Política de privacidad",
    //     fullName:"Nombre completo",
    //     fullNameIsRequired:"El nombre es obligatorio",
    //     fullNameInvalid:"El nombre debe tener al menos 3 caracteres.",
    //     email:"Email",
    //     emailIsRequired:"El correo electrónico es obligatorio",
    //     emailInvalid:"El correo electrónico no es válido.",
    //     phoneNumber:"Número de teléfono",
    //     phoneNumberIsRequired:"Se requiere número de teléfono",
    //     phoneNumberInvalid:"El número de teléfono debe tener al menos 9 caracteres.",
    //     message:"Mensaje",
    //     messageIsRequired:"El mensaje es obligatorio",
    //     messageInvalid:"El mensaje debe tener al menos 3 caracteres.",
    //     submit:"Mandar",
    //     monday:"Lunes",
    //     sunday:"Domingo",
    //     legal:"Fresco",
    //     Portuguese: "portugués",
    //     Spanish: "Español",
    //     English: "Inglés",
    //     German: "Alemán",
    //     French: "Francés",
    //     emailSent:"Email enviado correctamente."
    //   },
    // },
    // de: {
    //   translation: {
    //     home: "Home",
    //     contacts: "Contactos",
    //     about: "Sobre-nós",
    //     fleet: "Frota",
    //     address: "Morada",
    //     phone: "Telefone",
    //     informations:"Informações",
    //     termsAndConditions: "Termos e Condições",
    //     privacyPolicy: "Politica de Privacidade",
    //     fullName:"Nome Completo",
    //     fullNameIsRequired:"Nome é obrigatório",
    //     fullNameInvalid:"O nome deve ter pelo menos 3 caracteres",
    //     email:"Email",
    //     emailIsRequired:"email é obrigatório",
    //     emailInvalid:"O email não é valido",
    //     phoneNumber:"Número de telefone",
    //     phoneNumberIsRequired:"número de telefone é obrigatório",
    //     phoneNumberInvalid:"O número de telefone deve ter pelo menos 9 caracteres",
    //     message:"Mensagem",
    //     messageIsRequired:"Mensagem é obrigatório",
    //     messageInvalid:"A mensagem deve ter pelo menos 3 caracteres",
    //     submit:"Enviar",
    //     monday:"Segunda-Feira",
    //     sunday:"Domingo",
    //     legal:"Legal",
    //     Portuguese: "Portugues",
    //     Spanish: "Espanhol",
    //     English: "Inglês",
    //     German: "Alemão",
    //     French: "Francês",
    //   },
    // },
    // fr: {
    //   translation: {
    //     home: "Home",
    //     contacts: "Contactos",
    //     about: "Sobre-nós",
    //     fleet: "Frota",
    //     address: "Morada",
    //     phone: "Telefone",
    //     informations:"Informações",
    //     termsAndConditions: "Termos e Condições",
    //     privacyPolicy: "Politica de Privacidade",
    //     fullName:"Nome Completo",
    //     fullNameIsRequired:"Nome é obrigatório",
    //     fullNameInvalid:"O nome deve ter pelo menos 3 caracteres",
    //     email:"Email",
    //     emailIsRequired:"email é obrigatório",
    //     emailInvalid:"O email não é valido",
    //     phoneNumber:"Número de telefone",
    //     phoneNumberIsRequired:"número de telefone é obrigatório",
    //     phoneNumberInvalid:"O número de telefone deve ter pelo menos 9 caracteres",
    //     message:"Mensagem",
    //     messageIsRequired:"Mensagem é obrigatório",
    //     messageInvalid:"A mensagem deve ter pelo menos 3 caracteres",
    //     submit:"Enviar",
    //     monday:"Segunda-Feira",
    //     sunday:"Domingo",
    //     legal:"Legal",
    //     Portuguese: "Portugues",
    //     Spanish: "Espanhol",
    //     English: "Inglês",
    //     German: "Alemão",
    //     French: "Francês",
    //   },
    // },
  },
});

export default i18n;
