import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { List } from "flowbite-react";
import i18n from "./i18n";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#38AECC",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { t } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      {i18n.language === "pt" ? (
        <>
          {" "}
          <Container>
            <Typography
              variant="h3"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              {t("termsAndConditions")}
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{ textAlign: "center", float: "center", mt: "1rem" }}
            >
              <b>
                Escritório Rua do cano, 9 , 9100-109 Santa Cruz {<br />} Horário
                de funcionamento aberto desde as 8:30 até as 18:30 {<br />}{" "}
                Escritório Rua Ivens N 12 , 9000-046 funchal {<br />} Horário de
                funcionamento aberto desde as 8:30 até as 18:30
              </b>
            </Typography>
          </Container>
          <Container
            sx={{
              mt: "1rem",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
              }}
            >
              Serviço fora do horário de funcionamento Aeroporto
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{
                flexGrow: 1,
                textAlign: "justify",
                mt: "1rem",
                mr: "5rem",
                ml: "5rem",
              }}
            >
              Por cada levantamento a partir das <b>18:01 até 23:30</b> ou
              devolução a partir das <b>00:00 até 08:29</b> será cobrada uma
              taxa de <b style={{ color: "blue" }}>€30,00</b> euros.
              {<br />} Por cada levantamento a partir das <b>23:31 até 08:29</b>{" "}
              será cobrada uma taxa de <b style={{ color: "blue" }}>€ 55,00</b>{" "}
              euros.
              {<br />}
              Em caso de fora do horário devido a atraso de voo, as taxas
              indicadas serão aplicadas. {<br />}
              Levantamentos/Devoluções no Aeroporto fora do horário de
              funcionamento (sempre sujeitas a confirmação).
            </Typography>
          </Container>
          <Container
            sx={{
              mt: "1rem",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
              }}
            >
              Taxas / Extras
            </Typography>
            <List>
              <List.Item>
                <b>Cadeira de bebê isofix:</b> Também pode ser fornecido
                mediante solicitação. O Custo será{" "}
                <b style={{ color: "blue" }}> € 12,00</b> por dia em um máximo
                de <b style={{ color: "blue" }}>€ 96,00</b> por aluguer.
              </List.Item>
              <List.Item>
                <b>Cadeira para bebês:</b> Também pode ser fornecido mediante
                solicitação. O custo será{" "}
                <b style={{ color: "blue" }}>€ 8,00</b> por dia em um máximo de{" "}
                <b style={{ color: "blue" }}>€ 64,00</b> por aluguer.
              </List.Item>
              <List.Item>
                <b>Assento criança:</b> Também pode ser fornecido mediante
                solicitação. O custo sera{" "}
                <b style={{ color: "blue" }}>€ 4,00</b> por Dia em um máximo de{" "}
                <b style={{ color: "blue" }}>€ 32,00</b> por Aluguer.
              </List.Item>
              <List.Item>
                <b>Condutor joven: </b> Todos os condutores entre 21 e 25
                anos(inclusive) devem pagar uma taxa adicional de {""}
                <b style={{ color: "blue" }}>€ 40,00</b> por aluguer.
              </List.Item>
              <List.Item>
                <b> Condutor adicional: </b> Para cada condutor adicional
                mencionado no contrato de aluguer, uma taxa de
                <b style={{ color: "blue" }}> € 30,00</b> será cobrada.
              </List.Item>
              <List.Item>
                <b> Taxa de Aeroporto: </b>Para levantamentos e devoluções desde
                as 08:30 até 18:30, uma taxa de
                <b style={{ color: "blue" }}> € 25,00</b> será cobrada.
              </List.Item>
              <List.Item>
                <b>FERRY PORTO SANTO:</b>Também pode ser autorizado mediante
                solicitação com um custo de
                <b style={{ color: "blue" }}>€ 55,00</b> por aluguer.
              </List.Item>
            </List>
          </Container>
          <Container>
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              <i>
                Para ser coberto pelo seguro, os condutores devem ser
                devidamente mencionados no contrato de aluguer.
              </i>
            </Typography>
            <Container sx={{ mt: "1rem" }}>
              <List>
                <List.Item>
                  <b> Devolução com antecedência: </b> Não iremos Reembolsar o
                  cliente nem a agência envolvida na Reserva pelos dias de
                  aluguer que não foram usados.
                </List.Item>
                <List.Item>
                  <b> Não comparecer / não aceitar os termos acordados: </b>{" "}
                  Todos os dias de aluguer reservados serão faturados em caso de
                  não comparecer ou se o cliente não atender os requisitos de
                  aluguer.
                </List.Item>
                <List.Item>
                  <b> Alteração de reserva: </b> Para qualquer alteração 48h
                  antes da entrega da viatura, uma taxa de{" "}
                  <b style={{ color: "blue" }}> € 30,00</b> será cobrada
                  diretamente ao cliente.
                </List.Item>
              </List>
            </Container>
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              Muito importante
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              O cliente é sempre responsável por um franquia na quantidade de{" "}
              {""}
              <b style={{ color: "blue" }}>€ 1.000,00</b> para os{" "}
              <b>GRUPOS A, B, B2</b>,{" "}
              <b style={{ color: "blue" }}>€ 1.500,00</b> para{" "}
              <b>Grupos C, D, D1 e J </b>
              <b style={{ color: "blue" }}>€ 2.000,00</b> para{" "}
              <b>
                Grupos C1, C2, D2, E, E1, E2, G, H, H1, H2, J1, J2, K, K1, L, LD
              </b>
              , <b style={{ color: "blue" }}>€ 3.000,00</b> para{" "}
              <b>Grupos F, M, M1 e M2</b>,{" "}
              <b style={{ color: "blue" }}>€ 3.800,00</b> para o{" "}
              <b>Grupo L2.</b> No caso de um acidente auto-provocado, em Caso de
              danos causados ​​por atos de vandalismo, em caso de furto do
              veículo ou de furto de qualquer de seus acessórios, enquanto o
              condutor não possa identificar o culpado.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              Este depósito de segurança deve ser feito na entrega do carro por
              CARTÃO DE CRÉDITO.
            </Typography>
            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              O depósito de segurança referido será reembolsado ao cliente no
              fim do aluguer, caso nenhum acidente tenha <b> ocorrido </b>, Bem
              como nenhum dano causado ao veículo por atos de Vandalismo, roubo
              do veículo ou de qualquer um de seus acessórios.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              Esta situação só pode ser eliminada com o pagamento de {""}
              <b> Super C.D.W. (Seguro todos os riscos) </b>
              DE <b style={{ color: "blue" }}>€ 10,00 </b>Por dia para os{" "}
              <b>GRUPOS A, B, B2</b>, <b style={{ color: "blue" }}>€ 15,00</b>{" "}
              Por dia Para <b>Grupos C, D, D1 e J </b>,{" "}
              <b style={{ color: "blue" }}>€ 20,00</b> PARA{" "}
              <b>
                Grupos C1, C2, D2, E, E1, E2, G, H, H1, H2, J1, J2, K, K1, L e
                LD
              </b>
              , <b style={{ color: "blue" }}>€ 30,00</b> PARA{" "}
              <b>Grupos F, M, M1, M2 e L1</b>
              <b style={{ color: "blue" }}>€ 40,00</b> PARA <b>Grupo L2</b> Este
              valor deve ser pago pelo cliente Ao receber o veículo {""}
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              O seguro acima não elimina os danos ou atos de Vandalismo
              mencionado abaixo: Os clientes serão responsabilizados por Danos
              infligidos a pneus, vidros, jantes, espelhos externos, chassis e
              atos de vandalismo por desconhecidos ou não. Essa responsabilidade
              pode ser eliminada pela taxa de cobertura extra de
              <b style={{ color: "blue" }}> € 5,00</b>
              Por dia para <b>GRUPOS A, B, B2, C, D, D1 e J</b>,{" "}
              <b style={{ color: "blue" }}>€ 10,00</b> Por dia para{" "}
              <b>
                Grupos C1, C2, D2, E, E1, E2, G, H, H1, H2, J1, J2, K, K1, L e
                LD
              </b>
              , <b style={{ color: "blue" }}>€ 15,00</b> FOR{" "}
              <b>Grupos F, M, M1, M2, L1 e L2</b> a serem pagos pelo cliente no
              momento do levantamento {""}
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              <b> Exclusões: </b> Uso negligente / Violações de transito como
              multas de estacionamento, perda de chaves, combustível errado,
              interior do veículo, embreagem e caixa de velocidades.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              Em caso de acidente pela culpa do condutor, levando em
              Consideração da lei portuguesa, as companhias de seguros recusam
              qualquer ou Toda responsabilidade por esse condutor, de todos os
              danos causados ​​ao Veículo alugado ou para terceiros sempre que é
              detectado que o condutor estava dirigindo sob a influência de
              álcool, narcóticos, drogas ou produtos tóxicos ou por insanidade
              do condutor. Também se o cliente usa o carro em competições
              desportivas, corridas, rallys ou desafios, concursos, apostas,
              etc.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              Também aproveitamos esta oportunidade para informar que, mesmo que
              o condutor não esteja sob a influência de nenhum dos itens acima,
              mas por qualquer meio também destrói o carro por culpa própria,
              não iremos Reembolsar qualquer quantia para o tempo restante para
              a conclusão do aluguer. Apenas é possivel fornecer outro carro
              através da realização de uma nova fránquia que pode cobrir
              Eventuais danos que podem ser causados ​​ao novo carro.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem", mb: "1rem" }}
            >
              Todas as reservas que chegam ao nosso departamento de reservas com
              menos de 3 dias de antecendencia serão confirmadas mediante
              solicitação.
            </Typography>
          </Container>
        </>
      ) : (
        <>
          {" "}
          <Container>
            <Typography
              variant="h3"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              {t("termsAndConditions")}
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{ textAlign: "center", float: "center", mt: "1rem" }}
            >
              <b>
                Office Address Rua Do Cano, 9 , 9100-109 Santa Cruz {<br />}{" "}
                Working Hours Are From 8:30 To 18:30 {<br />} Office Address Rua
                Ivens N12 , 9000-046 Funchal {<br />} Working Hours Are From
                8:30 To 18:30
              </b>
            </Typography>
          </Container>
          <Container
            sx={{
              mt: "1rem",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
              }}
            >
              Airport Service Outside Of Opening Hours
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{
                flexGrow: 1,
                textAlign: "justify",
                mt: "1rem",
                mr: "5rem",
                ml: "5rem",
              }}
            >
              For each pick up from <b>18:01 To 23:30</b> or drop off from{" "}
              <b>23:31 TO 08:29</b> a fee of{" "}
              <b style={{ color: "blue" }}>30,00</b> will be charged directly to
              the customer.
              {<br />}
              Pick up from <b>23:31 to 08:29</b> A fee of{" "}
              <b style={{ color: "blue" }}>€ 55,00</b> will be charged.
              {<br />} In the event of flight delays causing this situation, out
              of hours <b>fee will apply</b>.{<br />}
              Pick up/Drop off outside of opening hours always upon{" "}
              <b>request</b>.{" "}
            </Typography>
          </Container>
          <Container
            sx={{
              mt: "1rem",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
              }}
            >
              Extra fees
            </Typography>
            <List>
              <List.Item>
                <b>Baby Seats Isofix:</b> May also be supplied on request. the
                cost for these will be <b style={{ color: "blue" }}>€ 12,00</b>
                per day on a maximum of{" "}
                <b style={{ color: "blue" }}>€ 96,00 </b>
                per rental.
              </List.Item>
              <List.Item>
                <b>Baby Seats:</b> May also be supplied on request. the cost for
                these will be <b style={{ color: "blue" }}>€ 8,00</b> per day on
                a maximum of <b style={{ color: "blue" }}>€ 64,00</b> per
                rental.
              </List.Item>
              <List.Item>
                <b>Booster Seat:</b> May also be supplied on request. the cost
                for these will be <b style={{ color: "blue" }}>€ 4,00</b> per
                day on a maximum of <b style={{ color: "blue" }}>€ 32,00</b> per
                rental.
              </List.Item>
              <List.Item>
                <b>Young Drivers:</b> All drivers between 21 and 25 years old
                must pay directly to why not an additional tax of
                <b style={{ color: "blue" }}>€ 40,00</b> per rental.
              </List.Item>
              <List.Item>
                <b>Additional Driver:</b> For each additional driver mentioned
                on the rental agreement a fee of
                <b style={{ color: "blue" }}>€ 30,00</b> will be charged.
              </List.Item>
              <List.Item>
                <b> Airport Fee: </b>For pick-Ups and returns from 08:30 to
                18:30, a fee of
                <b style={{ color: "blue" }}> € 25,00</b> will be charged.
              </List.Item>
              <List.Item>
                <b>Ferry Porto Santo:</b> May also be authorised on request with
                a cost of <b style={{ color: "blue" }}>€ 55,00</b> per rental.
                one way is not allowed.
              </List.Item>
            </List>
          </Container>
          <Container>
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              <i>
                To be covered by insurance, drivers must be duly mentioned on
                the rental agreement.
              </i>
            </Typography>
            <Container sx={{ mt: "1rem" }}>
              <List>
                <List.Item>
                  <b>Collecting In advance:</b> through this rate we shall not
                  refund either the client nor the agency involved in the
                  booking for the total or partial rental days which were not
                  used.
                </List.Item>
                <List.Item>
                  <b>No show / non-compliance with the agreed terms:</b> all
                  booked rental days will be invoiced in case of no show or if
                  client does not meet rental requirements.
                </List.Item>
                <List.Item>
                  <b>Booking alteration:</b> for any alteration 48h prior to car
                  delivery, a fee of <b style={{ color: "blue" }}>€ 30,00</b>
                  will be charged directly to the client.
                </List.Item>
              </List>
            </Container>
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              <i>Very Important</i>
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              The Client (Whether Driver Or Not) Is Always Responsible For An
              Excess On The Amount Of
              <b style={{ color: "blue" }}> € 1.000,00</b> For
              <b> Groups A, B, B2</b>,
              <b style={{ color: "blue" }}> € 1.500,00</b> For
              <b> Groups C, D, D1 E J</b>,
              <b style={{ color: "blue" }}> € 2.000,00</b> For
              <b>
                {" "}
                Groups C1, C2, D2, E, E1, E2, G, H, H1, H2, J1, J2, K, K1, L, LD
              </b>
              ,<b style={{ color: "blue" }}> € 3.000,00</b> For
              <b> Groups F, M, M1 E M2</b>,
              <b style={{ color: "blue" }}> € 3.800,00</b> For
              <b> Group L2.</b> In Case Of A Self-Provoked Accident, In Case Of
              Damages Caused By Acts Of Vandalism, In Case Theft Of The Vehicle
              Or Of Theft Of Any Of Its Accessories, As Long As The Driver Is
              Unable To Identify The Guilty Party.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              This Security Deposit Must Be Made Upon The Delivery Of The Car By
              Credit Card.
            </Typography>
            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              The Referred Security Deposit Will Be Refunded To The Client At
              The End Of The Rental, In Case <b>No</b> Accident Has Taken Place,
              As Well As No Damages Have Been Caused To The Vehicle By Acts Of
              Vandalism, Theft Of The Vehicle Or Of Any Of Its Accessories.
            </Typography>
            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              This Situation Can Only Be Eliminated With The Payment Of
              <b> Super C.D.W. </b>
              Of <b style={{ color: "blue" }}> € 10,00</b> Per Day For
              <b> Groups A, B, B2</b>,{" "}
              <b style={{ color: "blue" }}> € 15,00 </b>
              Per Day For <b> Groups C, D, D1 E J</b>,
              <b style={{ color: "blue" }}> € 20,00 </b> For
              <b>
                {" "}
                Groups C1, C2, D2, E, E1, E2, G, H, H1, H2, J1, J2, K, K1, L, LD
              </b>
              ,<b style={{ color: "blue" }}> € 30,00</b> For
              <b> Groups F, M, M1 E M2</b>,
              <b style={{ color: "blue" }}> € 40,00</b> For
              <b> Group L2.</b> This Amount Has To Be Paid By The Client When
              Receiving The Vehicle Always On
              <b style={{ color: "red" }}> A Minimum Charge Basis Of 3 Days</b>.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              The Above Cover Does Not Eliminate The Damages Or Acts Of
              Vandalism Mentioned Below: Clients Will Be Held Responsible For
              Damages Inflicted To Tires, Glasses, Wheel Rims, Exterior Mirrors,
              Undercarriage And Acts Of Vandalism By Unknown Or Not. This
              Responsibility Can Be Eliminated By The Extra Coverage Fee Of
              <b style={{ color: "blue" }}> € 5,00 </b>
              Per Day For <b> Grupos A, B, B2, C, D, D1 E J</b>,
              <b style={{ color: "blue" }}> € 10,00</b> Per Day For
              <b>
                {" "}
                Grupos C1, C2, D2, E, E1, E2, G, H, H1, H2, J1, J2, K, K1, L E
                LD
              </b>
              ,<b style={{ color: "blue" }}> € 15,00</b> For
              <b> Grupos F, M, M1, M2, L1 E L2</b> To Be Paid By The Client
              Directly To Why Not Car Rental On
              <b style={{ color: "red" }}> A Minimum Charge Basis Of 3 Days.</b>
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              <b>Exclusions:</b> Negligent Use / Traffic Violations As Parking
              Fines, Keys Loss, Fuel Error, Vehicle Interior, Clutch And Gear
              Box.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              In case of accident by our car driver's fault, taking into
              consideration portuguese law, insurance companies decline any or
              all responsibility for that driver of all damages caused to the
              hired vehicle or to third parties whenever it is detected that the
              driver was driving under the influence of alcohol, narcotics,
              drugs, or toxic products, or by insanity of the driver. also, if
              the client uses the car in sportive competitions, races, rallies
              or challenges, contests, bets, etc.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              We also take this opportunity to inform that even if the car's
              driver is not under the influence of any of the above items but by
              any means also destroys our car by his own fault, we shall not
              refund any amount for the time left to complete the rental, and we
              only give another car through a large deposit that may cover
              eventual damages that can be caused to the new car. we would like
              clients to be well informed about the above situation.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem", mb: "1rem" }}
            >
              All bookings arriving at our head office-booking department with
              less than 3 days to delivery time will be confirmed on request.
            </Typography>
          </Container>
        </>
      )}
    </ThemeProvider>
  );
};

export default TermsAndConditions;
