import React, { useEffect } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { Box, CircularProgress } from "@mui/material";

const PayPalCheckout = ({
  amount,
  currency,
  description,
  onApprove,
  onError,
}) => {
  const [{ isPending }] = usePayPalScriptReducer();
  useEffect(() => {}, [isPending]);

  const ButtonWrapper = () => {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
      <Box
        sx={{
          overflowY: "auto",
          padding: 1,
          boxShadow: 3,
          backgroundColor: "#fff",
        }}
      >
        {isPending ? (
          <CircularProgress />
        ) : (
          <PayPalButtons
            style={{
              layout: "vertical",
              color: "blue",
              shape: "rect",
              label: "pay",
            }}
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    description: description,
                    amount: {
                      currency_code: currency,
                      value: amount,
                    },
                  },
                ],
              });
            }}
            onApprove={onApprove}
            onError={onError}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      <ButtonWrapper />
    </>
  );
};

export default PayPalCheckout;
