import React, { useEffect, useState } from "react";
import moment from "moment";
import { GOOGLE_API_KEY, PLACE_CLIENT_ID } from "./config";
import {
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Reviews.css";
import Carousel from "react-multi-carousel";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#38AECC",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    // Define an async function to fetch data
    const fetchReviews = async () => {
      try {
        const response = await fetch(
          "https://www.whynotcarrental.com/Backend/googleReviews.php"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json(); // Await the JSON response
        setReviews(data); // Set the data after the promise resolves
      } catch (error) {
        setError(error.message);
      }
    };

    fetchReviews(); // Call the async function
  }, []);

  // const googReviews = [
  //   {
  //     name: "Sheila Ndungu",
  //     profileImage:
  //       "https://media.istockphoto.com/id/1466995518/photo/business-woman-and-worker-portrait-at-office-desk-as-administration-executive-company-manager.jpg?s=612x612&w=0&k=20&c=NvKeG6Fh0_VVfH_N0Ka-5j8284XJhL2VTJfe6IwDkWQ=", // Replace with the reviewer's actual image URL
  //     rating: 5,
  //     reviewText:
  //       "Great experience renting with RentX. Car was in excellent condition - new, clean and great to drive. Pick up and drop off was smooth. And the staff (Pedro ✨) was kind, friendly and very helpful... and even upgraded the car to a newer one. 5 stars!",
  //   },
  //   {
  //     name: "Joao Manel",
  //     profileImage:
  //       "https://i.pinimg.com/originals/be/a3/49/bea3491915571d34a026753f4a872000.jpg", // Replace with the reviewer's actual image URL
  //     rating: 5,
  //     reviewText:
  //       "Great experience renting with RentX. Car was in excellent condition - new, clean and great to drive. Pick up and drop off was smooth. And the staff (Pedro ✨) was kind, friendly and very helpful... and even upgraded the car to a newer one. 5 stars!",
  //   },
  //   {
  //     name: "Pedro Jardim",
  //     profileImage:
  //       "https://media.istockphoto.com/id/1664886899/photo/face-thinking-and-asian-man-in-studio-for-planning-decision-or-questions-on-grey-background.jpg?s=612x612&w=0&k=20&c=8aY4VOjesC4br-F2tomtj9bFglMRSl_fCeh4gQG-RSk=", // Replace with the reviewer's actual image URL
  //     rating: 5,
  //     reviewText:
  //       "Great experience renting with RentX. Car was in excellent condition - new, clean and great to drive. Pick up and drop off was smooth. And the staff (Pedro ✨) was kind, friendly and very helpful... and even upgraded the car to a newer one. 5 stars!",
  //   },
  //   // Add more review objects here if you have multiple reviews
  // ];

  return (
    <ThemeProvider theme={theme}>
      <Typography
        variant="h4"
        component="div"
        sx={{ color: "primary.main", textAlign: "center", mt: "1rem" }}
      >
        {t("reviews")}
      </Typography>
      <Box
        sx={{
          marginRight: { xs: 0, sm: 5 },
          marginTop: 2,
          display: "flex", // Flexbox layout
          justifyContent: { xs: "center", sm: "flex-end" }, // Center on small screens, right on larger screens
        }}
      >
        <Button
          sx={{ bottom: "10px" }}
          variant="contained"
          size="large"
          target="_blank"
          href="https://g.page/r/CTp1HNG-CGIzEBM/review/"
        >
          {t("leaveAReview")}
        </Button>
      </Box>
      <Carousel swipeable draggable infinite responsive={responsive}>
        {reviews?.map((review, index) => (
          <div key={index}>
            <Box key={index} sx={{ marginLeft: 5, marginRight: 5 }}>
              <div key={index} className="review-card">
                <div key={index} className="review-header">
                  <img
                    src={review?.profile_photo_url}
                    alt="Reviewer"
                    className="reviewer-image"
                  />
                  <div>
                    <h3>
                      {t("reviewOf")} {review.author_name}
                    </h3>
                    <div className="review-rating">
                      {Array(review.rating).fill("⭐")}
                    </div>
                  </div>
                </div>
                <p className="review-text">{review.text}</p>
              </div>
            </Box>
          </div>
        ))}
      </Carousel>
    </ThemeProvider>
  );
};

export default Reviews;
