import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalCheckout from "../../Reservation/PayPalCheckout";

const PaymentSelection = ({
  updatedTotalRentalValue,
  setPaypalOrderId,
  setPaymentAproved,
  setUpdatedTotalRentalValue,
  setPaymentType,
  paymentType,
  setTotalRentalValue,
}) => {
  const partialValue = (updatedTotalRentalValue * 0.2).toFixed(2);
  const [paymentValue, setPaymentValue] = useState(partialValue);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [proceedPayment, setProceedPayment] = useState(false);

  const { t } = useTranslation();
  const handlePaymentTypeChange = (event) => {
    if (event.target.value === "partial")
      setPaymentValue(partialValue) && setTotalRentalValue(partialValue);
    if (event.target.value === "full")
      setPaymentValue(updatedTotalRentalValue) &&
        setTotalRentalValue(updatedTotalRentalValue);

    setPaymentType(event.target.value);
  };

  const initialOptions = {
    "client-id":
      "AXVO04mtl9_KdA9UXQ5h7mqCgFfkk448HYka-rnBgbkjdJ4_Vo-86sBVG2hfVE7UIP8-maVSEJJ2HgFD",
    currency: "EUR",
    intent: "capture",
  };

  const onApprove = (data, actions) => {
    // Handle the payment approval here, like updating the UI or saving the order details.
    setUpdatedTotalRentalValue(paymentValue);
    return actions.order
      .capture()
      .then((details) => {
        setPaypalOrderId(data.orderID);
        setPaymentAproved(true);
      }) 
      .catch((err) => {});
  };

  const onError = (err) => {
    console.log(err, "Erro paypal");
  };

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  return (
    <Box
      sx={{
        maxWidth: "600px",
        margin: "0 auto",
        padding: "20px",
        boxShadow: 3,
      }}
    >
      {/* Payment Type Selection */}
      <Box
        sx={{ backgroundColor: "primary.main", color: "#fff", padding: "10px" }}
      >
        <Typography variant="h6" align="center">
          {t("choosePaymentMode")}
        </Typography>
      </Box>
      <FormControl component="fieldset" sx={{ mt: 2 }}>
        <RadioGroup value={paymentType} onChange={handlePaymentTypeChange}>
          <FormControlLabel
            value="partial"
            control={<Radio />}
            label={
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {t("partialPrice")}
                </Typography>
                <Typography variant="body2">{t("pay20Now80Later")}</Typography>
                <Typography variant="h6" color="error">
                  {partialValue}€
                </Typography>
              </Box>
            }
          />
          <FormControlLabel
            value="full"
            control={<Radio />}
            label={
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {t("totalPrice")}
                </Typography>
                <Typography variant="h6" color="error">
                  {Number(updatedTotalRentalValue).toFixed(2)}€
                </Typography>
              </Box>
            }
          />
        </RadioGroup>
      </FormControl>
      {/* Terms and Conditions */}
      <Box
        sx={{
          backgroundColor: "primary.main",
          color: "#fff",
          padding: "10px",
          marginTop: "20px",
        }}
      >
        <Typography variant="h6" align="center">
          {t("termsAndConditions")}
        </Typography>
      </Box>
      <FormGroup sx={{ mt: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={handleTermsChange}
              color="primary"
            />
          }
          label={
            <Typography variant="body2" component="span">
              {t("readAndAccepted")}{" "}
              <Typography
                variant="body2"
                component="span"
                sx={{ color: "primary.main", textDecoration: "underline" }}
              >
                <Link target="_blank" to="/termsAndConditions">
                  {t("termsAndConditions")}
                </Link>
              </Typography>
            </Typography>
          }
        />
      </FormGroup>

      {proceedPayment ? (
        <>
          <Box
            sx={{
              backgroundColor: "primary.main",
              color: "#fff",
              padding: "10px",
              marginTop: "20px",
            }}
          >
            <Typography variant="h6" align="center">
              {t("choosePaymentMethod")}
            </Typography>
          </Box>
          <PayPalScriptProvider options={initialOptions}>
            <PayPalCheckout
              amount={paymentValue}
              currency={"EUR"}
              description={"vehicleBooking"}
              onApprove={onApprove}
              onError={onError}
            />
          </PayPalScriptProvider>
        </>
      ) : null}

      {!proceedPayment ? (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          disabled={!termsAccepted}
          onClick={() => setProceedPayment(true)}
        >
          {t("proceedPayment")}
        </Button>
      ) : null}
    </Box>
  );
};

PaymentSelection.propTypes = {
  updatedTotalRentalValue: PropTypes.any,
  setPaypalOrderId: PropTypes.any,
  setPaymentAproved: PropTypes.any,
  setUpdatedTotalRentalValue: PropTypes.any,
  setPaymentType: PropTypes.any,
  paymentType: PropTypes.any,
  setTotalRentalValue: PropTypes.any,
};

export default PaymentSelection;
