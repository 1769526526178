import React, { useState } from "react";
import Contacts from "./Contacts";
import "./i18n";
import About from "./About";
import Body from "./Body";
import Fleet from "./Fleet";
import Reviews from "./Reviews";
const App = () => {
  const [quoteMessage, setQuoteMessage] = useState("");
  const [choosedCar, setChoosedCar] = useState({
    brand: null,
    code: null,
    picture: null,
  });
  return (
    <>
      <section id="body">
        <Body choosedCar={choosedCar} setChoosedCar={setChoosedCar} />
      </section>
      <section id="aboutUs">
        <About />
      </section>
      <section id="fleet">
        <Fleet
          setQuoteMessage={setQuoteMessage}
          setChoosedCar={setChoosedCar}
        />
      </section>
      {/* <section id="reviews">
        <Reviews />
      </section> */}
      <section id="contacts">
        <Contacts quoteMessage={quoteMessage} />
      </section>
    </>
  );
};
export default App;
