export function vehicleCodeChanger(code) {
  switch (code) {
    case "SCOOTER":
      return "O";
    case "TXT":
      return "TXT";
    case "MDMV":
      return "A";
    case "NBMV":
      return "B";
    case "NCMV":
      return "B2";
    case "EDMV":
      return "C";
    case "CFMV":
      return "C1";
    case "CDMV":
      return "C2";
    case "EDMD":
      return "D";
    case "HXMD":
      return "D1";
    case "CGMD":
      return "D2";
    case "CDMD":
      return "E";
    case "HDMD":
      return "E1";
    case "CMMD":
      return "E2";
    case "HDAE":
      return "EL";
    case "LVMD":
      return "F";
    case "RVAD":
      return "F1";
    case "CWMD":
      return "G";
    case "NTMV":
      return "H";
    case "CTMV":
      return "H1";
    case "CTAV":
      return "H2";
    case "MDAV":
      return "I";
    case "EDAV":
      return "J";
    case "CDAV":
      return "J1";
    case "CDAD":
      return "J2";
    case "SDMD":
      return "K";
    case "RFMD":
      return "K1";
    case "CGAV":
      return "L";
    case "CGAD":
      return "LD";
    case "DDAD":
      return "L1";
    case "PDAD":
      return "L2";
    case "SVMD":
      return "M";
    case "SFAD":
      return "M1";
    case "JVMR":
      return "M2";
    default:
      return "Code not found";
  }
}
